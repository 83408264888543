import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { inject, observer, Provider } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import { getCookies } from 'libs/cookies'
import 'assets/styles/index.scss'

import './libs'
import LoadingMask from 'ui-kit/loading-mask'

import { ThemeProvider } from 'containers/app/components/providers/ThemeProvider'
import { generalTheme } from 'containers/app/data/theme/generalTheme'
import stores from './stores'
import Routes from './routes'

const App = ({ userStore }: { userStore?: any }) => {
  useEffect(() => {
    if (getCookies('authToken')) {
      userStore.getItem()
    } else {
      userStore.toggleLoading(false)
    }
  }, [])

  if (userStore.loading) {
    return <LoadingMask />
  }

  return (
    <ThemeProvider theme={generalTheme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  )
}

const AppComponent = inject('userStore')(observer(App))
const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Provider {...stores}>
      <AppComponent />
    </Provider>
  </React.StrictMode>,
)
