import {ReactNode} from "react"
import {ConfigProvider} from "antd"

type IThemeProviderProps = {
    children: ReactNode,
    theme: any
}

export const ThemeProvider = ({children, theme}: IThemeProviderProps) => (
  <ConfigProvider
    theme={theme}
  >
    {children}
  </ConfigProvider>
)
