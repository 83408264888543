import UserStore from 'containers/shared/stores/user.store'
import SessionStore from 'containers/shared/stores/session.store'

interface IStores {
  userStore: UserStore
  sessionStore: SessionStore
}

const appStores: IStores = {
  userStore: new UserStore(),
  sessionStore: new SessionStore(),
}

export default appStores
