import { camelToSnakeCase } from 'utils/nameStyle.utils'

export const LANGUAGES = {
  en: {
    label: 'English',
    value: 'en',
  },
}

export const DEFAULT_LOCALE = 'en'
export const DEFAULT_FALLBACK_LOCALE = 'en'

export const LANGUAGES_OPTIONS = Object.values(LANGUAGES)
export const LANGUAGES_OPTIONS_KEYS = Object.keys(LANGUAGES).map((key) => camelToSnakeCase(key))

export const getSupportedLocale = (locale) =>
  LANGUAGES_OPTIONS_KEYS.includes(locale) ? locale : DEFAULT_FALLBACK_LOCALE
