import React from 'react'
import { useLocation, Outlet, Route, Routes } from 'react-router-dom'
import { inject } from 'mobx-react'

import {
  APP_ROOT_PATH,
  APP_USERS_CONFIRMATION_PATH,
  APP_USERS_CONFIRMATION_NEW_PATH,
  APP_USERS_SIGN_IN_PATH,
  APP_USERS_SIGN_UP_PATH,
  APP_USERS_PASSWORD_NEW_PATH,
  APP_USERS_PASSWORD_EDIT_PATH,
  APP_USERS_CONGRADULATE,
} from 'constants/routes.constants'

import LoadingMask from 'ui-kit/loading-mask'

const PageNotFound = React.lazy(() => import('containers/shared/screens/PageNotFound'))
const Landing = React.lazy(() => import('./screens/Landing'))
const SignIn = React.lazy(() => import('./screens/SignIn/SignIn'))
const SignUp = React.lazy(() => import('./screens/SignUp/SignUp'))
const ForgotPassword = React.lazy(() => import('./screens/ForgotPassword/ForgotPassword/ForgotPassword'))
const NewPassword = React.lazy(() => import('./screens/ForgotPassword/NewPassword'))
const NewConfirmation = React.lazy(() => import('./screens/ConfirmationForm/NewConfirmationForm'))
const ConfirmationHandler = React.lazy(() => import('./screens/ConfirmationForm/ConfirmationHandler'))
const Congradulate = React.lazy(() => import('./screens/Congradulate/Congradulate'))

const LoginRoute = inject('userStore')(({ userStore }: { userStore?: any }) => {
  const loggedIn = userStore.item.id
  const location = useLocation()

  const goToCabinet = () => {
    const params = new URLSearchParams(location.search)
    if (params.has('locale')) {
      params.delete('locale')
    }
    const searchParams = params.toString()
    const cabinetParams = searchParams ? `?${searchParams}` : ''

    window.location.href = userStore.getCabinetLink(cabinetParams)
    return null
  }

  return loggedIn ? goToCabinet() : <Outlet />
})

function RoutesList() {
  return (
    <React.Suspense fallback={<LoadingMask />}>
      <Routes>
        <Route path={APP_ROOT_PATH} element={<Landing />} />
        <Route path={APP_USERS_CONFIRMATION_PATH} element={<ConfirmationHandler />} />
        <Route path={APP_USERS_CONFIRMATION_NEW_PATH} element={<NewConfirmation />} />
        <Route element={<LoginRoute />}>
          <Route path={APP_USERS_SIGN_IN_PATH} element={<SignIn />} />
          <Route path={APP_USERS_SIGN_UP_PATH} element={<SignUp />} />
          <Route path={APP_USERS_PASSWORD_NEW_PATH} element={<ForgotPassword />} />
          <Route path={APP_USERS_CONGRADULATE} element={<Congradulate />} />
          <Route path={APP_USERS_PASSWORD_EDIT_PATH} element={<NewPassword />} />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesList
